<template>
  <div class="userNotice">
    <!-- 缴费提醒 -->
    <el-popover placement="bottom-end" v-model="active" trigger="hover" popper-class="popover">
          <p>消息提醒</p>
        <ul>
          <li v-for="item in noticeList" :key="item.id">
            <div class="notice_item">
              <div class="remark"> {{item.message}}</div>
              <ch-button type="delete" @click="deleteLi(item.id)" >已读</ch-button>
            </div>
            <div class="payTime">{{item.dateTime}}</div>
          </li>
          <li class="no" v-if="noticeList.length===0">暂无消息</li>
        </ul>
        <div class="deleteAll" v-if="noticeList.length>0">
          <div @click="watchModal">查看全部</div>
          <div @click="deletesAll">全部已读</div>
        </div>
        <div slot="reference" class="icon">
          <el-badge :value="value" :max="99">
            <ch-icon v-if="active" name="notice-active" ></ch-icon>
            <ch-icon v-else name="notice-notice" ></ch-icon>
          </el-badge>
        </div>
      </el-popover>
    <!-- 每天第一次登录 显示未读消息   -->
    <el-dialog
        title="消息提醒"
        :visible.sync="showModal"
        :close-on-click-modal="false"
        width="55vw"
        @closed="closeModal"
        class="ch-dialog"
    >
      <div class="dialog-wrapper">
        <ul>
          <li v-for="item in noticeList" :key="item.id">
            <div class="notice_item">
              <div class="message"> {{item.message}}</div>
              <ch-button class="ch-button" type="delete" @click="deleteLi(item.id,false)" >已读</ch-button>
            </div>
            <div class="payTime">{{item.dateTime}}</div>
          </li>
          <li class="no" v-if="noticeList.length===0">暂无消息</li>
        </ul>
      </div>

      <div slot="footer" class="flex_con">
        <div class="deleteAll" v-if="noticeList.length>0">
          <div @click="deletesAll(false)">全部已读</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data(){
    return{
      active: false,
      value: 0,
      showModal:false,
      noticeList: [],
    }
  },
  computed:{  
    ...mapGetters({
      noticeMsg: 'payment/noticeMsg',
      popup:'popup'
    })
  },
  watch:{
    noticeMsg: function(newValue){
      if(newValue){
        this.noticeList = this.noticeMsg
        this.value = this.noticeList?.length
      }
    },
  },
  created() {
    this.NoticeList()
  },
  methods: {
    ...mapMutations({
      saveUserNotice: 'payment/saveNoticeMsg',
      savePopup:"savePopup"
    }),
    NoticeList() {
      this.$curl.get("/hm/homePage/getNoticeList").then((res) => {
        this.noticeList = res.data || []
        this.value = this.noticeList.length
        if(this.noticeList.length<=0) return
        this.showModal = this.popup
      })
    },
    // 删除
    deleteLi(id,active = true) {
      this.$confirm('此操作将永久删除该消息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.$curl.delete(`/hm/homePage/delNotice/${id}`).then(() => {
            this.noticeList = this.noticeList.filter(item => item.id !=id)
            this.active = active
            this.value = this.noticeList.length
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });       
          this.active = true   
        });
      
    },
    deletesAll(active= true) {
      this.$confirm('此操作将永久删除全部消息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.$curl.delete("/hm/homePage/delNotice/all").then(() => {
          this.noticeList = []
          this.active = active
          this.value = 0
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    closeModal(){
      this.showModal = false
    },
    watchModal(){
      this.showModal=true
      this.active = false
    }
  }
 }
</script>
<style lang="scss" scoped>
  .userNotice{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    .active {
      color: #3E7EFF;
    }

  }
  .popover  {
    .title{
      display: flex;
      justify-content: space-between;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      padding: 15px 10px;
      color: #3D7FFF;
      cursor: pointer;
    }
    ul {
      height: 35vh;
      overflow: auto;
      li {
        width: 350px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        .notice_item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .remark{
            padding-right: 20px;
            white-space: pre-line;
          }
          .ch-button{
            padding-right: 20px;
          }
        }
        .payTime{
          font-size: 12px;
          color: #dddddd;
        }
        &:hover{
          background:#f2f9ff;
        }

      }

      .no{
        border: none;
      }
    }
    .deleteAll {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 10px 10px 0 10px;
      font-size: 16px;
      color: #3D7FFF;
      cursor: pointer;
    }
  }

  .dialog-wrapper{
    max-height: 500px;
    min-height: 300px;
    overflow: scroll;
    width: 90%;
    font-size: 16px;
    li {
      padding: 10px 30px 10px 10px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      .notice_item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .message{
          padding-right: 20px;
          white-space: pre-line;
        }
      }
      .payTime{
        font-size: 14px;
        color: #dddddd;
      }
      &:hover{
        background:#f2f9ff;
      }

    }
    .no{
      border: none;
    }

  }
.flex_con{
  .deleteAll {
    text-align: center;
    padding: 10px 0 0 0 ;
    font-size: 16px;
    color: #3D7FFF;
    cursor: pointer;
  }
}

</style>
<style lang="scss">
  .el-popper.popover {
    border-radius: 16px;
  }
  .remark {
    span {
      padding: 0 3px;
      color: #FB3A39 !important;
      font-weight: bold;
    }
  }
</style>